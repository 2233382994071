import {CS_SEND_DATA_ERROR, CS_SEND_DATA_SUCCESS, SHOW_CS_LOADING} from "../types";

const handlers = {
    [SHOW_CS_LOADING]: state => ({...state, loading: true}),
    [CS_SEND_DATA_SUCCESS]: state => ({...state, loading: false, success: true, error: null}),
    [CS_SEND_DATA_ERROR]: (state, {payload}) => ({...state, loading: false, error: payload}),
    DEFAULT: state => state
};

export const csReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};