import React from 'react';
import classes from './css/SignIn.module.css'
import {Col, Container, Row} from "react-bootstrap";
import {SignInForm} from "./SignInForm";


export const SignInPage = () => {

    return (
        <div className="main_container">
            <div className="App">
                <Container>
                    <Row xs={1} lg={2}>
                        <Col>
                            <div>
                                <div className={classes.sign_in_title}>
                                    Экзамен НОК
                                </div>
                                <div className={classes.sign_in_subtitle}>
                                    Специалист по финансовому консультированию
                                </div>
                            </div>
                            <br/>
                            <div className={classes.sign_in_title}>
                                <a href="https://play.google.com/store/apps/details?id=com.nok.finance">
                                    <img alt=""
                                         width="153px"
                                         src="http://fsfrexam.ru/googeicon.png"
                                    />
                                </a>
                            </div>
                        </Col>
                        <Col>
                            <SignInForm/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    );
};