import React, {useContext, useEffect} from 'react';
import {AuthContext} from "./auth/authContext";
import {useLocation, useNavigate} from "react-router-dom";
import {PaymentPage} from "../pages/payment/PaymentPage";
import {SignInPage} from "../pages/auth/SignInPage";

export const RouteLanding = () => {

    const { user } = useContext(AuthContext);

    let navigate = useNavigate();
    let location = useLocation();

    let from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if (user != null) {
            navigate(from, { replace: true });
        }
    },[user])

    return (
        user !== null ? <PaymentPage/> : <SignInPage/>
    )
}

