export const paymentHandleError = (code) => {
    switch (code) {
        case "TOKEN_NOT_FOUND":
            return "Ошибка при обработке платежа, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "EXPIRED_ID_TOKEN":
            return "Ошибка при обработке платежа, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "INVALID_ID_TOKEN":
            return "Ошибка при обработке платежа, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "USER_NOT_FOUND":
            return "Ошибка при обработке платежа, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "USER_DISABLED":
            return "Пользователь заблокирован, обратитесь к администратору";
        case "USER_IS_PAID":
            return "У вас уже оформлена покупка, если у вас появляется данное сообщение, пожалуйста напишите в поддержку support@nok-finance.ru"
        case "Product id not found":
            return "Ошибка при обработке платежа, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "Ошибка получения токена авторизации xsolla":
            return "Ошибка при обработке платежа, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "Ошибка получения ордера xsolla":
            return "Ошибка при обработке платежа, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        default:
            return "Ошибка не определена, обратитесь в поддержку support@nok-finance.ru"
    }
}