import React from "react";
import classes from './css/PaymentPage.module.css'
import {Col, Container, Row} from "react-bootstrap";
import {ProfileForm} from "./ProfileForm";

export const PaymentPage = () => {

    return (
        <div className="main_container">
            <div className="App">
                <Container>
                    <Row xs={1} lg={2}>
                        <Col>
                            <div className={classes.sign_in_title}>
                                Экзамен НОК
                            </div>
                            <div className={classes.sign_in_subtitle}>
                                Специалист по финансовому консультированию
                            </div>
                        </Col>
                        <Col>
                            <ProfileForm/>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}