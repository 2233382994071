import React, {useEffect, useReducer} from "react";
import {authReducer} from "./authReducer";
import {AUTH_ERROR, FETCH_USER, NOT_SHOW_AUTH_LOADING, SHOW_AUTH_LOADING, SIGN_OUT_AUTH} from "../types";
import {AuthContext} from "./authContext";
import {app, provider} from "../base";
import axios from "axios";
import {authHandleError} from "../../helpers/authHandleError";
import {fbHandleError} from "../../helpers/fbHandleError";

const qsUrl = 'https://api.nok-finance.ru'

export const AuthState = ({children}) => {

    const initialState = {
        loading: false,
        user: null,
        authError: null
    };

    const [state, dispatch] = useReducer(authReducer, initialState);

    useEffect(() => {

        showLoader();

        const unsubscribe = app.auth()
            .onAuthStateChanged(user => {
                if (user) {
                    user.getIdToken(true).then(token => {
                        findUser(token)
                    })
                } else {
                    dispatch({type: NOT_SHOW_AUTH_LOADING});
                }
            });
        return () => unsubscribe();
    }, []);

    const showLoader = () => dispatch({type: SHOW_AUTH_LOADING});

    const findUser = (token) => {

        axios.get(qsUrl + "/user", {
            headers: {
                'Authorization': token
            }
        }).then(result => {
            const payload = result.data.request_result
            dispatch({type: FETCH_USER, payload});
        }).catch(error => {
            if (error.response.data.message === "USER_NOT_FOUND") {
                createUser(token)
            } else {
                const payload = authHandleError(error.response.data.message);
                dispatch({type: AUTH_ERROR, payload});
            }
        })
    }

    const googleSignIn = async () => {

        showLoader();

        app.auth()
            .signInWithPopup(provider)
            .then(result => {

            }).catch(function(error) {
            dispatch({type: AUTH_ERROR, error});
        });
    };

    const signIn = async (email, password) => {

        showLoader();

        app.auth()
            .signInWithEmailAndPassword(email, password)
            .then(result => {

            }).catch(error => {
            const payload = fbHandleError(error.code)
            dispatch({type: AUTH_ERROR, payload});
        });
    };

    const signUp = async (email, password) => {

        showLoader();

        app.auth()
            .createUserWithEmailAndPassword(email, password)
            .then(result => {

            }).catch(function(error) {
            const payload = fbHandleError(error.code)
            dispatch({type: AUTH_ERROR, payload});
        });
    };

    const signOut = async () => {
        app.auth()
            .signOut()
            .then(function() {
                dispatch({type: SIGN_OUT_AUTH});
            }).catch(error => {
            const payload = fbHandleError(error.code)
            dispatch({type: AUTH_ERROR, payload});
        });
    };

    const createUser = (token) => {

        axios.post(qsUrl + "/user", null,{
            headers: {
                'Authorization': token
            }
        }).then(result => {
            const payload = result.data.request_result
            dispatch({type: FETCH_USER, payload});
        }).catch(error => {
            const payload = authHandleError(error.response.data.message);
            dispatch({type: AUTH_ERROR, payload});
        })
    }

    return (
        <AuthContext.Provider value={{
            showLoader,
            googleSignIn,
            signIn,
            signUp,
            signOut,
            loading: state.loading,
            user: state.user,
            authError: state.authError
        }}>
            {children}
        </AuthContext.Provider>
    )
};