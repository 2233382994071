import React, { useContext } from "react";
import {AuthContext} from "./auth/authContext";
import {Navigate, Outlet, Redirect, Route, useLocation} from "react-router-dom";

export const PrivateRoute = () => {

    const { user } = useContext(AuthContext);
    let location = useLocation();

    if (!user) {
        return <Navigate to="/" state={{ from: location }} replace/>;
    }
    return <Outlet/>;
};