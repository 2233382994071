import React from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {CsState} from "./context/comingsoon/CsState";
import {AuthState} from "./context/auth/AuthState";
import {PrivateRoute} from "./context/PrivateRoute";
import {PaymentPage} from "./pages/payment/PaymentPage";
import {RouteLanding} from "./context/RouteLanding";
import {PaymentState} from "./context/payment/PaymentState";

const App = () => {

    return (
        <BrowserRouter>
            <AuthState>
                <PaymentState>
                    <CsState>
                        <Routes>
                            <Route path={'/'} exact element={ <RouteLanding/> }/>
                            <Route element={<PrivateRoute />}>
                                <Route path='/courses/payment' element={<PaymentPage/>}/>
                            </Route>
                            <Route path="*" element={<Navigate to="/" replace />}/>
                        </Routes>
                    </CsState>
                </PaymentState>
            </AuthState>
        </BrowserRouter>
    );
}

export default App;
