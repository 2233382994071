export const fbHandleError = (code) => {
    switch (code) {
        case "auth/invalid-email":
           return "Неверный адрес почты"
        case "auth/user-disabled":
            return "Пользователь заблокирован, обратитесь к администратору"
        case "auth/user-not-found":
            return "Пользователь не найден"
        case "auth/wrong-password":
            return "Неверный пароль"
        case "auth/account-exists-with-different-credential":
            return "Воспользуйтесь другим способов входа email/google"
        case "auth/email-already-in-use":
            return "Email уже используется"
        case "auth/cancelled-popup-request":
            return "Вход отменен"
        case "auth/popup-blocked":
            return "Заблокированы всплывающие окна"
        case "auth/popup-closed-by-user":
            return "Вход отменен"
        default:
            return "Ошибка не определена, обратитесь в поддержку support@nok-finance.ru"
    }
}