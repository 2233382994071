import {FETCH_PAYMENT_ERROR, FETCH_PAYMENT_URL, FETCH_PRODUCTS, SHOW_PAYMENT_LOADING} from "../types";

const handlers = {
    [SHOW_PAYMENT_LOADING]: state => ({...state, paymentLoading: true, url: null, paymentError: null}),
    [FETCH_PAYMENT_URL]: (state, {payload}) => ({...state, url: payload, paymentLoading: false, paymentError: null}),
    [FETCH_PRODUCTS]: (state, {payload}) => ({...state, product: payload, paymentLoading: false, paymentError: null}),
    [FETCH_PAYMENT_ERROR]: (state, {payload}) => ({...state, url: null, paymentLoading: false, paymentError: payload}),
    DEFAULT: state => state
};

export const paymentReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
