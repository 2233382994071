export const SHOW_AUTH_LOADING = 'SHOW_AUTH_LOADING';
export const NOT_SHOW_AUTH_LOADING = 'NOT_SHOW_AUTH_LOADING';
export const SIGN_OUT_AUTH = 'SIGN_OUT_AUTH';
export const AUTH_ERROR = 'AUTH_ERROR';

export const SHOW_CS_LOADING = 'SHOW_CS_LOADING';
export const CS_SEND_DATA_SUCCESS = 'CS_SEND_DATA_SUCCESS';
export const CS_SEND_DATA_ERROR = 'CS_SEND_DATA_ERROR';

export const FETCH_USER = 'FETCH_USER';
export const FETCH_PRODUCTS = 'FETCH_PRODUCTS';

export const SHOW_PAYMENT_LOADING = 'SHOW_PAYMENT_LOADING';
export const FETCH_PAYMENT_URL = 'FETCH_PAYMENT_URL';
export const FETCH_PAYMENT_ERROR = 'FETCH_PAYMENT_ERROR';

