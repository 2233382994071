import React, {useContext, useEffect, useState} from "react";
import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import {AuthContext} from "../../context/auth/authContext";
import classes from './css/LogForm.module.css';

export const SignInForm = () => {

    const [isSignIn, changeSignIn] = useState(true)

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const {loading, googleSignIn, signIn, signUp, authError} = useContext(AuthContext);

    return (
        <div style={{display: 'flex', justifyContent: 'center', marginTop: 16}}>
            <div
                className={classes.form_relative}
                style={{background: 'rgba(255, 255, 255, 0.35)'}}>
                { loading ?
                    <div className={classes.loading_form}>
                        <Spinner animation="border" variant="secondary" />
                    </div>
                    : null }
                <div className={classes.form_content}>
                    <div className={classes.form_title}>
                        { isSignIn ? "Войти" : "Бесплатная регистрация" }
                    </div>
                    <div style={{height: 36}}/>
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Control
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Control
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            { authError !== null ?
                                <div className={classes.invalid_feedback}>{authError}</div>
                                :
                                <div style={{height: 12}}/>
                            }
                        </Form.Group>
                        <Row>
                            <Col>
                                <div className="d-grid gap-2">
                                    <Button
                                        className={classes.success_button}
                                        onClick={() => { isSignIn ? signIn(email, password) : signUp(email, password)} }
                                    >
                                        { isSignIn ? "Войти" : "Регистрация" }
                                    </Button>
                                </div>
                            </Col>
                            { isSignIn ?
                                <Col xs={3} md="auto">
                                    <Button
                                        variant="light"
                                        onClick={() => googleSignIn()}
                                    >
                                        <img className={classes.form_img_g} src={"img/G1.png"} alt=""/>
                                    </Button>
                                </Col>
                                : null }
                        </Row>
                    </Form>
                    <div className={classes.form_description}>
                        Регистрируясь, Вы соглашаетесь с условиями <a href="https://nok-finance.ru/license_agreement.html" target="_blank" rel="noopener noreferrer">Пользовательского соглашения</a> и <a href="https://nok-finance.ru/privacy_ru.html" target="_blank" rel="noopener noreferrer">Политикой конфиденциальности</a>
                    </div>
                    <div onClick={() => changeSignIn(state => !state)} className={classes.form_change}>
                        { isSignIn ? "Регистрация" : "Уже есть аккаунт" }
                    </div>
                </div>
            </div>
        </div>
    )
}