import React, {useReducer} from "react";
import {csReducer} from "./csReducer";
import {CsContext} from "./csContext";
import axios from "axios";
import {CS_SEND_DATA_ERROR, CS_SEND_DATA_SUCCESS, SHOW_CS_LOADING} from "../types";
import {errorHandler} from "./errorHandler";

const nokUrl = "https://api.nok-finance.ru/"

export const CsState = ({children}) => {

    const initialState = {
        success: false,
        loading: false,
        error: null
    };

    const [state, dispatch] = useReducer(csReducer, initialState);

    const showLoader = () => dispatch({type: SHOW_CS_LOADING});

    const setComingSoon = (email, platform) => {

        showLoader()

        axios.post(nokUrl + "coming-soon", {
            email: email, platform: platform
        }).then(result => {
            if (result.data.success) {
                dispatch({type: CS_SEND_DATA_SUCCESS});
            }
        }).catch(error => {
            const payload = errorHandler(error.response.data.message)
            dispatch({type: CS_SEND_DATA_ERROR, payload});
        })
    }

    return (
        <CsContext.Provider value={{
            setComingSoon,
            success: state.success,
            loading: state.loading,
            error: state.error
        }}>
            {children}
        </CsContext.Provider>
    )
}
