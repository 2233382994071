import React, {useReducer} from "react";
import {paymentReducer} from "./paymentReducer";
import {FETCH_PAYMENT_ERROR, FETCH_PAYMENT_URL, FETCH_PRODUCTS, SHOW_PAYMENT_LOADING} from "../types";
import {PaymentContext} from "./paymentContext";
import {app} from "../base";
import axios from "axios";
import {paymentHandleError} from "../../helpers/paymentHandleError";
import {isMobile} from 'react-device-detect';

const qsUrl = 'https://api.nok-finance.ru'
// const qsUrl = 'http://localhost:8080'

export const PaymentState = ({children}) => {

    const initialState = {
        url: null,
        paymentLoading: false,
        paymentError: null,
        product: null
    };

    const [state, dispatch] = useReducer(paymentReducer, initialState);

    const showPaymentLoader = () => dispatch({type: SHOW_PAYMENT_LOADING});

    const pay = (productId) => {

        showPaymentLoader()

        const user = app.auth().currentUser
        const body = {
            product_id: productId,
            email: user.email,
            version: isMobile ? "mobile" : "desktop"
        }
        if (user) {
            user.getIdToken(true).then(token => {
                axios.put(qsUrl + "/xsolla/payment", body,{
                    headers: {
                        'Authorization': token
                    }
                }).then(result => {
                    const payload = result.data.request_result
                    dispatch({type: FETCH_PAYMENT_URL, payload})
                }).catch(error => {
                    const payload = paymentHandleError(error.response.data.message)
                    dispatch({type: FETCH_PAYMENT_ERROR, payload})
                })
            });
        }
    }

    const getProducts = async () => {

        showPaymentLoader();

        const user = app.auth().currentUser
        if (user) {
            user.getIdToken(true).then(token => {
                axios.get(qsUrl + "/products?platform=web", {
                    headers: {
                        "Authorization": token
                    }
                }).then(result => {
                    const payload = result.data.request_result[0]
                    if (payload != null) {
                        dispatch({type: FETCH_PRODUCTS, payload})
                    }
                }).catch(error => {
                    console.log(error)
                })
            })
        }
    }

    return (
        <PaymentContext.Provider value={{
            pay,
            getProducts,
            paymentLoading: state.paymentLoading,
            url: state.url,
            product: state.product,
            paymentError: state.paymentError
        }}>
            {children}
        </PaymentContext.Provider>
    )
}
