export const errorHandler = (message) => {
    switch (message) {
        case "EMAIL_NOT_SPECIFIED":
            return "Укажите Email";
        case "PLATFORM_NOT_SPECIFIED":
            return "Укажите платформу";
        case "EMAIL_NOT_VALID":
            return "Укажите верный Email"
        default: return "Произошла ошибка при сохранении данных, попробуйте позже"
    }
}