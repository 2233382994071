import {AUTH_ERROR, FETCH_USER, NOT_SHOW_AUTH_LOADING, SHOW_AUTH_LOADING, SIGN_OUT_AUTH} from "../types";

const handlers = {
    [SHOW_AUTH_LOADING]: state => ({...state, loading: true}),
    [NOT_SHOW_AUTH_LOADING]: state => ({...state, loading: false}),
    [FETCH_USER]: (state, {payload}) => ({...state, loading: false, user: payload, authError: null}),
    [AUTH_ERROR]: (state, {payload}) => ({...state, loading: false, authError: payload}),
    [SIGN_OUT_AUTH]: state => ({...state, loading: false, user: null}),
    DEFAULT: state => state
};

export const authReducer = (state, action) => {
    const handle = handlers[action.type] || handlers.DEFAULT;
    return handle(state, action);
};
