import React, {Fragment, useContext, useEffect, useState} from "react";
import Image from 'react-bootstrap/Image'
import {AuthContext} from "../../context/auth/authContext";
import classes from './css/PaymentPage.module.css'
import {Button, Spinner, Stack} from "react-bootstrap";
import {PaymentContext} from "../../context/payment/paymentContext";
import noLogo from "../../helpers/img/ellipse.png";

export const ProfileForm = () => {

    const { user } = useContext(AuthContext);

    const {paymentLoading, url, pay, getProducts, product, paymentError} = useContext(PaymentContext)
    const [redirect, setRedirect] = useState("");

    useEffect(() => {
        getProducts();
    }, [])

    const onPay = () => {
        pay(product.id);
    }

    useEffect(() => {
        if (url !== null) {
            setRedirect(url);
        }
    },[url])

    useEffect(() => {
        if (redirect !== "") {
            window.location.replace(redirect)
        }
    }, [redirect])

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div style={{marginTop: 16}}>
            <Stack gap={3}>
                <Image style={{margin: 'auto'}} width={100} height={100} roundedCircle src={user.avatar}
                       onError={(e) => {
                           e.target.src=noLogo
                       }}/>
                <div className={classes.name_text}>{user.name} {user.name === "Student" ? `(${user.email})` : null}</div>
                {!user.paid ?
                    <Fragment>
                        <div className={classes.payment_title}>Купив тренажер,<br/>Вы получите:</div>
                        <div style={{margin: 'auto'}}>
                            <ul>
                                <li>Доступ ко всем главам</li>
                                <li>Режим экзамена</li>
                                <li>Статистика</li>
                            </ul>
                        </div>
                        <div style={{margin: 'auto'}}>
                            <Button
                                style={{paddingLeft: 32, paddingRight: 32, margin: '0 auto', display:'block'}}
                                variant="outline-light"
                                onClick={() => onPay()}
                                disabled={paymentLoading || product === null }
                            >
                                { paymentLoading || product === null ?
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    /> : null }
                                Купить за {product && product.price}&#8381;
                            </Button>
                            <div style={{marginTop: 8, fontSize: 14}}>
                                Скачать в Google Play по ссылке <a style={{color: '#fff'}} href="https://play.google.com/store/apps/details?id=com.nok.finance">NOK Finance</a>
                            </div>
                        </div>
                        {paymentError !== null ?
                            <div className={classes.invalid_feedback}>
                                { paymentError }
                            </div>
                            : null
                        }
                    </Fragment>
                    :
                    <Fragment>
                        <div className={classes.payment_title}>У вас полная версия тренажера</div>
                        <div style={{margin: 'auto'}}>
                            <Button
                                style={{paddingLeft: 32, paddingRight: 32}}
                                className={classes.success_button}
                                onClick={() => openInNewTab('https://play.google.com/store/apps/details?id=com.nok.finance')}>
                                Перейти в приложение
                            </Button>
                        </div>
                    </Fragment>
                }
            </Stack>
        </div>
    )
}