export const authHandleError = (code) => {
    switch (code) {
        case "TOKEN_NOT_FOUND":
            return "Ошибка при авторизации, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "EXPIRED_ID_TOKEN":
            return "Ошибка при авторизации, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "INVALID_ID_TOKEN":
            return "Ошибка при авторизации, попробуйте повторно, если ошибка повторится обратитесь в поддержку support@nok-finance.ru";
        case "USER_NOT_FOUND":
            return "Зарегистрируйтесь в приложение Экзамен НОК Специалист по финансовому консультированию, установив через Google Play или AppStore";
        case "USER_DISABLED":
            return "Пользователь заблокирован, обратитесь в поддержку support@nok-finance.ru";
        case "USER_FOUND":
            return "Пользователь с такими данными уже существует";
        default:
            return "Ошибка не определена, обратитесь в поддержку support@nok-finance.ru";
    }
}